import React from "react";

const Giveaway = () => {
  return (
    <div className="giveaway-container">
      <img
        src="../img/montlygiveaway.png"
        alt="Immagine Giveaway"
        className="rotated-image"
      />
      <div className="text-container">
        <h2 className="txt-giveaway">Every month you can win nft!</h2>
        <h3>
          WHAT ARE YOU WAITING FOR?
          <br /> CLICK AND PARTICIPATE NOW!
        </h3>
        <h5> ONLY ON X (TWITTER)</h5>
        <div className="cont-giveaway-img">
          <a
            href="https://twitter.com/idiots__club?s=21&t=nGXN2M-b620S_Nxy5DfWiw"
            target="_blank"
            className="giveaway-link"
          >
            <img src="img/twittergiveaway.png" alt="Twitter Giveaway" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Giveaway;
